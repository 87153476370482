import { Memo } from "@ecp-boxes/helpers/memoWrapper";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import {
  A11y,
  Autoplay,
  Controller,
  Navigation,
  Pagination,
  Swiper as SwiperProps,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useObserveElementDimensions } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { isClientSide, isPortalSide } from "../../helpers/helpers";
import InnerBox from "../../shared/components/InnerBox/InnerBox";
import {
  BoxSliderDisplayScheme,
  createBoxSliderSlideContentScheme,
  createBoxSliderSlideContentSettingsScheme,
} from "./BoxSlider.scheme";
import { IBoxSliderProps, SliderPaginationOptions } from "./BoxSlider.types";
import { StyledSlider } from "./StyledSlider";
import { counterBullet } from "./StyledSlider.methods";

const BoxSlider: React.FC<IBoxSliderProps> = (props: IBoxSliderProps) => {
  const [swiper, setSwiper] = useState<SwiperProps | null>(null);

  const {
    advanceSettings: {
      settings: { backgroundUrl },
    },
  } = useTheme() as IThemeState;

  const BoxSliderSlideContentSettingsScheme =
    createBoxSliderSlideContentSettingsScheme(backgroundUrl);

  const contentSettings = props.contentSettings?.slides?.map((slide) =>
    BoxSliderSlideContentSettingsScheme.parse(slide)
  );

  const currentSlideIndex =
    props.content?.slides?.findIndex(
      (slide) => slide.id + props.id === props.pathExtraSettings
    ) + 1 || 1;

  const BoxSliderSlideContentScheme =
    createBoxSliderSlideContentScheme(backgroundUrl);

  const content = props.content?.slides?.map((slide) => {
    return BoxSliderSlideContentScheme.parse(slide);
  });

  const displaySettings = BoxSliderDisplayScheme.parse(props.displaySettings);

  const [imgWrapper] = useObserveElementDimensions({
    width: 360,
    height: displaySettings.customHeight || Number(displaySettings.size),
  });

  const getPaginationStyle = () => {
    switch (displaySettings?.paginationStyle) {
      case SliderPaginationOptions.NUMBERS:
        return {
          pagination: {
            clickable: true,
            renderBullet: counterBullet,
          },
        };
      case SliderPaginationOptions.DOTS:
        return {
          pagination: {
            clickable: true,
            renderBullet: undefined,
          },
        };
      case SliderPaginationOptions.ARROWS:
        return {
          pagination: false,
          navigation: true,
        };

      default:
        return { navigation: false, pagination: false };
    }
  };

  if (swiper != null && !isPortalSide()) {
    swiper.slideTo(currentSlideIndex, 0);
  }

  useEffect(() => {
    if (swiper != null) {
      swiper.pagination.render();
    }
  }, [displaySettings.paginationStyle]);

  return (
    <StyledSlider
      ref={imgWrapper}
      $settings={{ ...displaySettings, ...contentSettings }}
      data-testid="boxSlider"
      className="slider-container slider-custom-style"
    >
      <Swiper
        observer={true}
        modules={[Navigation, A11y, Autoplay, Pagination, Controller]}
        loop={true}
        {...getPaginationStyle()}
        onSwiper={setSwiper}
        controller={{ control: swiper || undefined }}
        autoplay={
          displaySettings.slidesRotate.length > 0 && isPortalSide()
            ? {
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }
            : false
        }
      >
        {[...Array(Math.min(contentSettings.length, content.length)).keys()]
          ?.filter((_, i) => isClientSide() || i === 0) //filter only first slide on server side
          .map((_, index) => {
            return (
              <SwiperSlide
                key={content[index].id}
                data-testid="slider-slide"
                data-swiper-autoplay={
                  displaySettings.slidesRotate.length > 0 && isPortalSide()
                    ? String(displaySettings.slidesRotate[index] * 1000)
                    : undefined
                }
                className="slider-container__slide"
              >
                {contentSettings[index].image.show && (
                  <ImageWrapper
                    imageUrl={content[index].image.imageUrl}
                    seoDescription={content[index].seoDescription}
                    imageFit={contentSettings[index].image.fit}
                    imageAlignment={contentSettings[index].image.alignment}
                    opacity={contentSettings[index].image.opacity}
                    height={
                      displaySettings.size === "CUSTOM"
                        ? displaySettings.customHeight
                        : parseInt(displaySettings.size)
                    }
                    imgTagAsBackground
                    link={content[index].slideUrl}
                  />
                )}
                <InnerBox
                  content={content[index]}
                  settings={{ ...contentSettings[index], ...displaySettings }}
                  messages={{
                    box_content_description: content[index].description,
                    box_content_title: content[index].title,
                    box_content_main_button_heading:
                      content[index].mainButton.heading,
                    box_content_secondary_button_heading:
                      content[index].secondaryButton.heading,
                  }}
                  className="slider-container__slide-inner-box"
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </StyledSlider>
  );
};

export default Memo(BoxSlider);
